@import '../../../utils/variables.scss';

.container {
    max-width: $container-max;
    font-family: $primary-font, sans-serif;
    margin: 0 auto;
    
    .matrixHeader {
        margin-top: -110px;
        margin-bottom: 45px;
        min-height: 150px;
        padding: 15px;
        background: $color-gray-4;
        border-radius: $sticker-radius;
        box-shadow: 10px 11px 5px -11px rgba(0,0,0,0.75);

        .pageHeader {
            font-family: $primary-font, sans-serif;

            .title {
                margin-top: 3px;
                margin-left: 3px;
                padding: 0;
                font-weight: 600;
                color: #ffffff;
            }

            h3 {
                padding: 0;
                margin-left: 3px;
                font-weight: 300;
                color: #ffffff;   
            }
        }

        .matrixForm {
            background-color: #383838;
            border-bottom: 3px solid #e7cb75;
            border-radius: $sticker-radius;
            padding: 15px;
            margin-top: 30px;
            box-shadow: 10px 11px 5px -11px rgba(0,0,0,0.75);

            @media screen and (min-width: 800px) {  
                max-width: 50%;
                margin: 30px auto;
            }
        }
    }
  }

  
  .more {
    box-shadow:  0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-top: 7px solid #e7cb75;
    border-bottom: 7px solid #e7cb75;
    padding: 40px 0px 40px 0px;
    background-color: #111111;
    overflow: hidden;

    .space {
        @media screen and (max-width: 1167px) {  
            margin: 15px !important;
        } 
    }
 }

#matrixEngine {
    @media screen and (max-width: 1167px) {  
      margin: 15px !important;
    }
}