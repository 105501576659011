.fileWrapper {
    margin-bottom: 50px;

    .fileTable {
        font-family: "Open Sans", sans-serif;
    }

    .bar {
        background-color: #6b6b6b;
        font-size: 18px;
        font-weight: 600;

        &.highlighted {
            background-color: #e7cb75;
        }

        .title {
            font-size: 17px;
            flex: 1 1 80%; 
        }
    }
}