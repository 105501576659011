.items {
    margin-bottom: 40px;

    .itemTable {
        font-family: "Open Sans", sans-serif;
    }

    .whiteCheckbox {
        .MuiCheckbox-root {
            color: #ffffff !important;
        }
    }

    .MuiTablePagination-toolbar {
        padding: 8px;
        font-size: 16px !important;
    }

    .bar {
        background-color: #6b6b6b;
        font-size: 18px;
        font-weight: 600;

        &.highlighted {
            background-color: #e7cb75;
        }

        .title {
            font-size: 17px;
            flex: 1 1 80%; 
        }
    }
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: #ffffff !important;
}