@import '../../../utils/variables.scss';

.window {
    font-family: $primary-font, sans-serif;
    font-size: $primary-fontsize;
    font-weight: $font-semibold;
    // height: 600px;

    @media screen and (max-width: 932px) {  
        padding-left: 25px;
        padding-right: 25px;
    }

    h1 {
        font-family: $primary-font, sans-serif;
        
        color: #111111;
        text-transform: uppercase;
        -webkit-text-stroke: 1px $color-gold;

        @media screen and (min-width: 932px) {  
            font-size: 55px;
        }

        @media screen and (max-width: 932px) {  
            font-size: 35px;
            text-align: center;
            padding: 0;
            margin-bottom: 45px;
        }

        .filled {
            color: $color-gold;
        }
    }

    p {
        font-size: 20px;
        color: $color-white;
        font-weight: 300;
    }

    .brandImage {
        max-width: 100%;
        margin-top: 80px;
    }

    .appWrapper {
        @media (max-width:480px)  { 
            display: none;
          }
    }
    
    .catalog {
        margin-top: 40px;
        text-transform: uppercase;
        transition: ease-in 0.3s;

        @media screen and (max-width: 932px) {  
            text-align: center;
        }

        .catalogImage {
            max-width: 100%;
        }

        .title {
            color: #e7cb75;
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 7px;
            margin-top: 14px;
        }

        a, .poseAsUrl {
            color: #ffffff;
            text-decoration: none;
            font-weight: 200;
            display: block;
            cursor: pointer;

            &:hover {
              color: #e7cb75;
            }
          }
    }
}