@import '../../utils/variables.scss';

.cookie-message {
  font-family: "Open Sans", sans-serif;

  .MuiDialog-paper {
    background-color: #000000;
    background-image: url(https://i.imgur.com/ufjfsFE.png);
    background-position: bottom right;
    background-repeat: no-repeat;
    box-shadow: 10px 11px 5px -11px rgba(0, 0, 0, 0.75);
  }

  .MuiDialogTitle-root {
    color: #e7cb75;
    font-size: 26px;
    text-transform: uppercase;
    font-weight: bold;
    font-style: italic;
  }

  .MuiDialogContent-root {
    overflow-y: hidden;
  }

  p {
    font-weight: 400;
    color: #fff;
    margin: 0;
  }

  .MuiButton-label {
    color: #e7cb75;
    font-weight: 700;
  }

  .settings {
    color: #ffffff;

    .MuiIconButton-label {
      color: #fff;
    }

    .Mui-checked {
      .MuiIconButton-label {
        color: #e7cb75;
      }
    }

    span {
      margin-top: -1px;
    }
  }

}
