@import '../../../utils/variables.scss';

.error {
    background-color: #c0392b;
    border-radius: 6px;
    padding: 25px;
    margin-top: -110px;
    margin-bottom: 45px;
    font-family: $primary-font, sans-serif;

    .title {
        color: #ffffff;
        font-size: 26px;
        font-weight: 600;
        outline: 0px;
        text-transform: uppercase;
        padding: 0;
        margin: 0;
    }

    .description {
        font-size: 20px;
        color: #ffffff !important;
        font-weight: 300;
    }
}