@import '../../../utils/variables.scss';

.container {
    max-width: $container-max;
    margin: 0 auto;

    .search {
        margin-top: -110px;
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 12px;
        background-image: url("https://i.imgur.com/ufjfsFE.png");
        background-color: #000000;
        background-position: bottom right;
        background-repeat: no-repeat;
        border-radius: $sticker-radius;
        box-shadow: 10px 11px 5px -11px rgba(0,0,0,0.75);
        border: 6px solid #e7cb75;
    }

    .flipbook {
        margin-top: 45px;

        @media (max-width:480px)  { 
            display: none;
        }
    }
  }

.storefront {
    box-shadow:  0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-bottom: 7px solid #e7cb75;
    padding: 40px 0px 40px 0px;
    background-color: #111111;
    overflow: hidden;
}