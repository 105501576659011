@import '../../../utils/variables.scss';

.container {
    max-width: $container-max;
    font-family: $primary-font, sans-serif;
    margin: 0 auto;
    
    .productData {
        @media screen and (max-width: 1167px) {  
            margin: 15px !important;
        }
    }
    
    .productShowcase {
        margin-top: -110px;
        margin-bottom: 45px;
        min-height: 150px;
        padding: 15px;
        background: $color-gray-4;
        border-radius: $sticker-radius;
        box-shadow: 10px 11px 5px -11px rgba(0,0,0,0.75);

        .productImage {
            margin-top: 20px;
            max-width: 100%;
            border-radius: $sticker-radius;
        }

        .gallery {
            margin-top: 10px;

            .galleryItem {
                padding: 3px;
                float: left;
                background: #383838;
                border-radius: 3px;
                cursor: pointer;
                
               .placement {
                   border-radius: 3px;
                   width: 40px;
                   height: 50px;
                   background-size: cover;
                   background-position: center center;
                   background-repeat: no-repeat;
               }

               &:hover {
                   background: #e7cb75;
               }
            }
        }

        .pageHeader {
            font-family: $primary-font, sans-serif;

            .crumb {
                font-weight: 400;
                font-size: 20px;
                color: #ffffff;
                padding: 0;
                margin-bottom: 0;
            }

            .title {
                margin-top: 3px;
                padding: 0;
                font-weight: 600;
                color: #ffffff;
            }

            h3 {
                padding: 0;
                font-weight: 300;
                color: #ffffff;   
            }
        }
    }
  }

  .more {
    box-shadow:  0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-top: 7px solid #e7cb75;
    border-bottom: 7px solid #e7cb75;
    padding: 40px 0px 40px 0px;
    background-color: #111111;
    overflow: hidden;

    .items {
        @media screen and (max-width: 1167px) {  
            margin-left: 15px !important;
            margin-right: 15px !important;
        } 
    }
}

#productWrapper {
    @media screen and (max-width: 1167px) {  
      margin: 15px !important;
    }
}

.backButton {
    color: #ffffff;
    display: inline-block;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    background-color: #a09f9f;
    text-align: right;
    text-transform: uppercase;
    cursor: pointer;
    transition: ease-in 0.3s;
    float: right;
    margin-bottom: 2px;
    margin-top: 8px;
    box-shadow: 10px 11px 5px -11px rgba(0, 0, 0, 0.75);

    &.dark {
        background-color: #6f6a6a;
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }

    &:hover {
        background-color: #e7cb75 !important;

        i {
            color: #ffffff !important;
        }
    }
}

.compilation {
    margin-left: 15px;
    padding: 3px 7px 3px 7px;
    font-size: 14px;
    background: #e7cb75;
    border-radius: 7px;
    display: inline-block;
}

.delete {
    color: #3c3b3b;
    font-size: 12px;
    cursor: pointer;
    float: right;
    margin-top: -13px;

    &:hover {
        color: #ff2b2b;
    }
}

.clear {
    clear: both;
}