@import '../../utils/variables.scss';

body {
  margin: 0;  
  overflow-x: hidden;
  background-color: #383838;
}

.data {
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding-top: $margin-header;
  width: $width-data;
  background: url(https://beta.german-gold.de/wp-content/uploads/2020/11/Header-Database-02.jpg);
  background-position: bottom 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 7px solid #e7cb75;
  overflow: hidden;
  height: 260px;
}

header {
  padding: 0;
  left: 0;
  right: 0;
  z-index: 33;
  position: absolute;

  .container {
    max-width: $container-max;
    margin: 0 auto;
  }

  .main {
    left: 0;
    right: 0;
    height: $height-base;
    background: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 8 !important;

    .branding {
      width: $width-branding;
      
      @media screen and (min-width: 932px) {  
        position: absolute;
        height: $height-base;
      }

      img {
        width: $width-branding;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    .menu {
      float: right;
      
      text-transform: uppercase;
      font-family: $primary-font, sans-serif;
      font-size: $primary-fontsize;
      font-weight: $font-semibold;

      ul {
        li {
          @media screen and (max-width: 932px) {  
            display: none;
          }
          
          margin-top: 35px;
          display: inline-block;
          color: $color-white;
          margin-left: $margin-menu-item;
          margin-right: $margin-menu-item;
          padding-bottom: 3px;

          &:hover {
            color: $color-gold;
            border-bottom: 2px solid $color-gold;
          }

         
        }

        a {
          text-decoration: none;
          color: $color-white;
        }

        a:hover {
          color: $color-gold;
        }

        a.active {
          color: $color-gold;
          
          li {
            color: $color-gold;
            border-bottom: 2px solid $color-gold;
          }
        }
        
      }
    }

    .sticker {
      display: none;
      width: auto;
      padding: 3px;
      float: right;
      border-bottom-right-radius: $sticker-radius;
      border-bottom-left-radius: $sticker-radius;
      margin-top: -$margin-header;
      background: $color-gray-4;
      box-shadow: 10px 11px 5px -11px rgba(0,0,0,0.75);
      z-index: 13 !important;
    }
  }
}

.app-switcher-content {
  z-index: 999 !important;
}

.searchTitle {
  margin-top: 14px;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
  color: #e7cb75;
  font-size: 26px;

  @media (max-width:480px)  { 
    display: none;
  }

  .italic {
    font-weight: 200;
    font-style: italic;
  }

  .bold {
    font-weight: bold;
  }
}

.algolia {
  text-align: center;

  a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.7);
    transition: all .4s ease-in;

    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }

  .text {
    font-size: 14px;
  }

  .icon {
    font-size: 25px;
  }
}

.hit {
  background-color: #565656 !important;
  padding: 8px;
  color: #ffffff !important;
  cursor: pointer;

  &:hover {
    background-color: #787575 !important;
  }
}

#visualize {
  @media screen and (max-width: 1167px) {  
    margin: 15px !important;
  }
}

.MuiInputBase-root, .MuiInputBase-input, .MuiFilledInput-root {
  background-color: #ffffff !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #111111 !important;
}

#toptoast {
  background-color: rgb(192, 57, 43);
  color: #ffffff;
  text-align: center;
  padding: 8px;
  margin-top: -30px;

  p {
    font-family: $primary-font, sans-serif;
    font-size: $primary-fontsize;
  }
}