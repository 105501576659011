@import '../../../utils/variables.scss';

.ctab {
    position: fixed;
    right: 50px;
    bottom: 35px;

    .MuiButtonBase-root {
        background-color: #444444;
        color: rgb(107, 107, 107);
        font-weight: 600;

        .fas {
            color: rgb(107, 107, 107);
            padding-right: 10px;
        }

        &:hover {
            background-color: #e84118;
            color: #ffffff;

            .fas {
                color: #ffffff;
            }
        }
    }
}

.MuiDrawer-paper {
    background-color: #6b6b6b !important;
    max-width: 500px;
    padding: 25px;
    box-shadow: 10px 11px 5px -11px rgba(0, 0, 0, 75%);

    .pageHeader {
        font-family: $primary-font, sans-serif;

        .title {
            margin-top: 10px;
            padding: 0;
            font-weight: 600;
            font-size: 21px;
            color: #ffffff;
        }

        h3 {
            padding: 0;
            font-weight: 300;
            font-size: 18px;
            color: #ffffff;   
        }
    }
}
