.items {
    margin-top: 50px;
    margin-bottom: 50px;

    .outer {
        overflow: hidden;
        background-color: #000000;
        position: relative;

        &.small {
            max-height: 80px;
        }

        .name {
            text-transform: uppercase;
            font-family: "Open Sans", sans-serif;
            font-size: 19px;
            font-weight: 600;
            color: #ffffff;
            position: absolute;
            z-index: 999;
            width: 70%;
            margin-left: 15px;
            margin-top: 15px;
        }

        .item {
            background-size: cover;
            min-height: 190px;
            text-align: center;
            cursor: pointer;
            overflow: hidden;
            transition: all 0.5s ease;
            opacity: 0.4;
    
            &:hover {
                color: #000000;
                transform: scale(1.2);
                opacity: 1;
                font-size: 19px; 
            } 
        }
    }

    .blockHeader {
        margin-bottom: 25px;

        h3 {
            font-size: 23px;
            color: #ffffff;
            font-weight: 300;
            font-weight: 600;
            padding: 0;
            margin: 0;
        }

        p {
            font-size: 17px;
            color: #ffffff;
            font-weight: 300;
            padding: 0;
            margin: 0;
        }
    }
    
}

