@import '../../../utils/variables.scss';

.container {
    max-width: $container-max;
    font-family: $primary-font, sans-serif;
    margin: 0 auto;
    
    .categoryHeader {
        margin-top: -110px;
        margin-bottom: 45px;
        min-height: 150px;
        padding: 15px;
        background: $color-gray-4;
        border-radius: $sticker-radius;
        box-shadow: 10px 11px 5px -11px rgba(0,0,0,0.75);

        .categoryImage {
            min-height: 180px;
            border-radius: $sticker-radius;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }

        .pageHeader {
            font-family: $primary-font, sans-serif;

            .title {
                margin-top: 10px;
                padding: 0;
                font-weight: 600;
                color: #ffffff;
            }

            h3 {
                padding: 0;
                font-weight: 300;
                color: #ffffff;   
            }
        }
    }
  }

  
  .more {
    box-shadow:  0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-top: 7px solid #e7cb75;
    border-bottom: 7px solid #e7cb75;
    padding: 40px 0px 40px 0px;
    background-color: #111111;
    overflow: hidden;

    .space {
        @media screen and (max-width: 1167px) {  
            margin: 15px !important;
        } 
    }
 }

#categoryEngine {
    @media screen and (max-width: 1167px) {  
      margin: 15px !important;
    }
}