/**
* GERMAN-GOLD WEB VIEWER VARIABLES
* Update German Gold styles with the following variables.
*
* 1. Font style
* 2. Colors
* 3. Header style
* 4. Height style
* 5. Width style
*/

/**
* 1. FONT STYLE
* primary-font: Our primary font family.
* primary-fontsize: Our primary font size.
* font-semibold: Our Semibold font style.
*/
$primary-font: "Open Sans";
$primary-fontsize: 17px;
$title-fontsize: 21px;
$content-fontsize: 15px;

$font-semibold: 600;

/**
* 2. COLORS
* color-black: Our default black color.
* color-gold: Our default gold color.
* color-white: Our default white color.
*/
$color-black: rgb(0,1,0);
$color-gold: #e7cb75;
$color-white: #ffffff;
$color-gray-1: #2a2a2a;
$color-gray-2: #353434;
$color-gray-3: #4a4a4a;
$color-gray-4: #6b6b6b;

/**
* 3. HEADER STYLE
* container-max: Our page container width.
* margin-header: The pixels between the top and header. (Padding)
* margin-menu-item: The pixels on every side of a menu item.
*/
$container-max: 1100px;

$margin-header: 30px;
$margin-menu-item: 12px;

$sticker-radius: 5px;

/**
* 4. HEIGHT STYLE
* height-base: Our default height. (Menu's and boxes)
* height-data: The height of our primary data element.
* height-search: The height of our search element.
*/
$height-base: 115px;
$height-data: calc(70vh);
$height-search: 70px;

/**
* 5. WIDTH STYLE
* width-branding: The width of our branding element (logo).
* width-data: The width of our primary data element.
*/
$width-branding: 200px;
$width-data: 100vw;