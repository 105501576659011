@import '../../utils/variables.scss';

footer {
  max-width: 100vw;
  background: linear-gradient(90deg, rgba(29,29,27,1) 0%, rgba(85,84,84,1) 50%, rgba(30,30,28,1) 100%);
  box-shadow: inset 0px 7px 5px 0px rgba(0,0,0,0.3);
  padding-bottom: 60px;
  overflow: hidden;

  .branding {
    width: $width-branding;
    margin:30px auto;

    img {
      width: $width-branding;
    }
  }

  .columns {
    padding-top: 20px;
    padding-bottom: 30px;
    font-family: $primary-font, sans-serif;
    text-align: center;

    .container {
      max-width: $container-max;
      margin: 0 auto;

      h1 {
        text-transform: uppercase;
        font-size: $title-fontsize;
        font-weight: $font-semibold;
        color: $color-gold;
      }

      .columnContent {
        text-transform: uppercase;
        color: #ffffff;
        font-size: $content-fontsize;

        .bold {
          font-weight: $font-semibold;
        }
      }
    }
  }

  .flag {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 9px;
    border-top: 9px solid #000000;
    background-color: #dd0000;
    border-bottom: 9px solid #ffce00;
    box-shadow: 0 0 9px hsla(0,0%,0%,.46);
  }

  .box {
    margin: 4px;
    background-color: #3a3a38;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0 0 9px rgba(27, 21, 21, 0.22);
    height: 100%;

    a {
      color: #ffffff;
      text-decoration: none;
      transition: ease-in 0.3s;

      &:hover {
        color: #e7cb75;
      }
    }

    @media screen and (max-width: 932px) {  
      margin: 14px;
      padding: 8px;
    }

    &.sparkles {
      background-image: url("https://i.imgur.com/dtkrfkH.png");
      background-position: bottom right;
      background-repeat: no-repeat;
    }

    &.app {
      text-align: left;
      margin-top: 50px;
      padding: 25px;
    }

    .seal {
      margin-top: 8px;
      width: 60%;
    }

    .footerMenu {
      transition: ease-in 0.3s;

      .title {
        color: #e7cb75;
        font-size: 16px;
        font-weight: 500;
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        a {
          color: #ffffff;
          text-decoration: none;

          &:hover {
            color: #e7cb75;
          }

          li {
            padding: 4px;
          }
        }
      }
    }
  }
} 